import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBjN_zlbU3okCaablewWR3Kj8uEEfnwxnQ",
  authDomain: "chat-c7a49.firebaseapp.com",
  databaseURL: "https://chat-c7a49-default-rtdb.firebaseio.com",
  projectId: "chat-c7a49",
  storageBucket: "chat-c7a49.appspot.com",
  messagingSenderId: "981608522265",
  appId: "1:981608522265:web:e7ad359276e723a8270088"
};


// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
